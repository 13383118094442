import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import("../../components/user/user.module").then(m => m.UserModule)
  },
  {
    path: 'orders',
    loadChildren: () => import("../../components/orders/orders.module").then(m => m.OrdersModule)
  },
  {
    path: 'services',
    loadChildren: () => import("../../components/services/services.module").then(m => m.ServicesModule)
  }
];
