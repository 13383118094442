import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { RequestService } from "src/app/services/http-service.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private fb: FormBuilder,
    public router: Router,
    public http: RequestService) {
    this.loginForm = this.fb.group({
      login: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() { }

  login() {
    if (this.loginForm.value["login"] != "" && this.loginForm.value["password"] != "") {
      let user = {
        'email': this.loginForm.value["login"],
        'password': this.loginForm.value["password"],
      };
      this.http.post('auth/admin', user).then((res) => {
        console.log(res);
        localStorage.setItem("token", res.token);        
        this.router.navigate(["/dash"]);  
      }, err => {
        alert("Неправильний логін або пароль");
      });
    } else {
      alert("Заповніть усі поля");
    }
  }

  registr() {
    this.router.navigate(["/auth/registration"]);
  }

  showPassword() {
    this.show = !this.show
  }
}
